.popular-ads-outer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    justify-content: center;
    max-width: 1600px;
    margin: 0 auto;
}

.popular-ads-inner {
    padding: var(--spacing-lg);
    max-width: 1600px;
    margin: 2rem;
}

.outer-popular-ads-copy {
    width: 100%;
    min-height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.popular-ads-copy-wrapper {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1600px;
    margin: 0 auto;
}

.popular-ads-copy-wrapper a {
    color: var(--green)
}

.popular-ads-copy-intro h1 {
    color: var(--green);
    font-size: var(--font-size-large);
}

.popular-ads-copy-intro h2 {
    font-size: var(--font-size-medium);
    text-align: left;
    margin-bottom: 1.2rem;
    white-space: wrap;
    margin-bottom: 1.2rem;
}

.popular-ads-copy-intro p {
    font-size: var(--font-size-xx-small);
}


.popular-ads-copy-list-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
    margin-top: 4rem;
    font-size: var(--font-size-xx-small);
}

.popular-ads-copy-outro {
    p {
        margin-bottom: 1rem;
        font-size: var(--font-size-xx-small);
    }
}

@media only screen and (max-width: 980px) {
    .popular-ads-copy-wrapper {
        margin-left: 2rem;
        margin-right: 2rem;
    }
}

@media only screen and (max-width: 720px) {
    .popular-ads-copy-wrapper {
        margin-left: 2rem;
        margin-right: 2rem;
    }
}

@media only screen and (max-width: 980px) {

    .popular-ads-copy-intro h1 {
        font-size: var(--font-size-mobile-large);
    }

    .popular-ads-copy-intro h2 {
        font-size: var(--font-size-mobile-small);
    }


    .outer-popular-ads-copy {
        padding: 0;
        margin: 0;
    }
}