.coworking-outer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1600px;
  margin: 0 auto;
}
.coworking-inner {
  padding: var(--spacing-lg);
  max-width: 1600px;
  margin: 2rem;
}
.coworking-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: auto;
  place-items: center;
}
.intro-img {
  max-width: 40rem;
  width: 100%;
  height: 30rem;
  align-self: center;
  object-fit: contain;
}
.intro-text {
  padding-bottom: 5%;
  display: flex;
  flex-direction: column;
  white-space: wrap;
}

.intro-text h1 {
  font-size: var(--font-size-large);
  color: var(--green);
}

.intro-text h2 {
  text-align: left;
  font-size: var(--font-size-medium);
  margin-bottom: 1.2rem;
}

.intro-text p {
  font-size: var(--font-size-xx-small);
}

.intro-text .active-link-button {
  width: auto;
  padding: 1rem 2rem 1rem;
  margin-top: 2rem;
  font-size: var(--font-size-x-small);
  font-weight: 700;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  color: var(--text-black);
  background-color: var(--white);
  box-shadow: var(--shadow-light);
  border-radius: 50px;
  border: none;
  align-self: center;
}

@media only screen and (min-width: 1300px) {
  .intro-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center;
    height: auto;
  }
}

@media only screen and (max-width: 980px) {
  .intro-content {
    padding: 0;
  }
  .coworking-container {
    display: block;
  }
  .coworking-inner {
    padding: 0;
    margin: 0;
  }
  .intro-text h2 {
    font-size: var(--font-size-mobile-small);
  }
}

@media only screen and (max-width: 400px) {
  .intro-text {
    margin: 2rem !important;
  }
}
