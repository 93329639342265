/* Base Styles */
.rent-out-space-landlord-section {
  display: grid;
  grid-template-columns: auto 50rem;
  align-items: end;
  padding: var(--spacing-lg);
  margin: 2rem;
}


.rent-out-space-landlord-info>h1 {
  font-size: var(--font-size-large);
  color: var(--green);
  overflow-wrap: wrap;
}

.rent-out-space-landlord-info>h2 {
  font-size: var(--font-size-medium);
  text-align: left;
  margin-bottom: 1.2rem;
  /*   overflow-wrap: anywhere; */
}

.rent-out-space-landlord-info>p {
  font-size: var(--font-size-xx-small);
}

.rent-out-space-landlord-info>.rounded-link-button {
  color: var(--white);
  background-color: var(--orange);
  font-size: 1.8rem;
  padding: 2rem;
  font-weight: 700;

  border: none;
  border-radius: 50px;
  box-shadow: var(--shadow-light);
  transition: all 0.15s ease-in-out;
  width: 30rem;
}

.rent-out-space-landlord-info>.rounded-link-button:hover {
  cursor: pointer;
  box-shadow: var(--shadow-medium);
  transform: scale(1.05);
}

.rent-out-space-landlord-steps span {
  font-size: var(--font-size-x-small);
}

/* Image Styles */
#how-it-works-landlord-image {
  grid-column: 2 / 3;
  justify-self: center;
  align-self: end;
  max-width: 46rem;
  min-width: 400px;
  height: auto;
  margin-right: var(--spacing-lg);
  margin-bottom: 1.5rem;
}

/* Landlord Steps Styles */
.rent-out-space-landlord-steps {
  grid-column: 1 / span 2;
  display: flex;
  justify-content: center;
  padding-top: var(--spacing-xl);
  gap: 2rem;
}

.rent-out-space-landlord-steps>div {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.rent-out-space-landlord-steps>div>div {
  flex-shrink: 0;
  position: relative;
  width: 5rem;
  height: 5rem;
  font-weight: 700;
  color: #1e1e1e;
  border-radius: 100px;
  border: 3px solid var(--green);
}

.rent-out-space-landlord-steps>div>div>span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.rent-out-space-landlord-steps>div>b {
  font-size: var(--font-size-x-small);
  margin-top: 1rem;
  font-weight: 700;
  height: 4rem;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.rent-out-space-landlord-steps>div>p {
  font-size: var(--font-size-xx-small);
  margin-bottom: 0;
  width: 90%;
  text-align: center;
}

.rent-out-space-landlord-info-text {
  grid-column: 1 / span 2;
}

.rent-out-space-landlord-info-text>p {
  font-size: var(--font-size-xx-small);
  margin-top: 2rem;
}

/* Media Queries */
@media only screen and (max-width: 1450px) {
  .rent-out-space-landlord-section {
    grid-template-columns: 70rem 38rem;
  }
}

@media only screen and (max-width: 1230px) {
  .rent-out-space-landlord-section {
    grid-template-columns: 1fr 38rem;
  }
}

@media only screen and (max-width: 1100px) {
  .rent-out-space-landlord-section {
    grid-template-columns: 1fr 35rem;
  }
}

@media only screen and (max-width: 980px) {
  .rent-out-space-landlord-section {
    padding: 0;
  }

  .rent-out-space-landlord-info>h1 {
    font-size: var(--font-size-mobile-large);
  }

  .rent-out-space-landlord-info>h2 {
    font-size: var(--font-size-mobile-small);
  }
}

@media only screen and (max-width: 1040px) {
  .rent-out-space-landlord-section {
    grid-template-columns: 1fr;
  }


  #how-it-works-landlord-image,
  .rent-out-space-landlord-steps,
  #landlord-bottom-text {
    grid-column: 1 / -1;
  }



  #how-it-works-landlord-image {
    grid-row: 2 / 3;
    justify-self: center;
    padding-top: var(--spacing-sm);
    min-width: 80%;
  }

  .rent-out-space-landlord-steps {
    grid-row: 3 / 4;
    gap: 3rem;

    &>div>p {
      white-space: normal;
    }
  }
}

@media (max-width: 767px) {
  .rent-out-space-landlord-steps {
    display: flex;
    flex-direction: column;

    &>div {
      margin-right: 0;
      padding: 1rem 0;
    }
  }

  #how-it-works-landlord-image {
    width: 80%;
    margin: 0 auto;
  }
}