.testimonial-section {
  overflow: hidden;
  margin-top: 4rem;
  margin-bottom: 0rem;
  position: relative;
}

.testimonial-section h3 {
  font-size: var(--font-size-small);
  font-weight: 800;
  color: var(--green);
  align-self: center;
  text-align: center;
  margin-bottom: 4rem;
}

.testimonial-slider {
  width: 100%;
  position: relative;
}

.testimonial-img-mobile-holder {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  align-content: center;
}

.testimonial-img-mobile-holder img {
  height: 100px;
  width: 200px;
}

.slick-slide.slick-active:nth-child(9) img {
  height: 95px;
  width: 120px;
}

.testimonial-img-mobile-holder p {
  font-size: var(--font-size-xx-small);
  margin: 1rem;
  text-align: center;
  max-width: 300px;
}

.testimonial-arrows {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  display: flex;
  justify-content: space-between;
  left: 0;
  right: 0;
}

.testimonial-arrow {
  width: 3.8rem;
  height: 3.8rem;
  padding: 0.4rem;
  color: var(--text-gray);
  background-color: var(--white);
  border-radius: 50%;
  cursor: pointer;
}

.testimonial-arrow-prev {
  padding-right: 0.6rem;
}

.testimonial-arrow-next {
  padding-left: 0.6rem;
}

.need-help-section {
  text-align: center;
  margin-top: 5rem;
  padding-bottom: 4rem;
  padding: 2rem;

  h1 {
    margin-bottom: 1rem;
  }

  p {
    font-size: var(--font-size-xx-small);
    margin-bottom: 4rem;
  }
}

.need-help-header {
  font-size: var(--font-size-x-small);
}

@media (max-width: 1250px) {
  .testimonial-section {
    margin-top: 0rem;
  }

  .need-help-section {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

@media (max-width: 600px) {
  .testimonial-section {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .need-help-section {
    padding-bottom: 0rem;
  }
}
