.edit-ad-container {
  max-width: 100rem;
}

.edit-ad-form-buttons-disabled-info {
  color: red;
  white-space: normal;
  margin-top: 3rem;
}

.form-control {
  width: 95%;
}

.radio-group {
  margin-left: -12px;
}

.shit-radio-button {
  visibility: hidden;
  cursor: pointer;
}

.custom-radio-button {
  height: 17px;
  width: 17px;
  border-radius: 50%;
  cursor: pointer;
  background-color: #fff;
  display: inline-block;
  border: 2px solid var(--green);
}

.edit-ad-input-fastighet {
  width: 43rem !important;
}

.shit-radio-button:checked+span {
  background-color: var(--green);
}

.edit-ad-input-row {
  display: flex;
}

.edit-ad-input-col {
  margin-right: 2rem;
  margin-bottom: 2.4rem;

  &-sm {
    flex-basis: 15%;
  }

  &-md {
    flex-basis: 20%;
  }

  &-lg {
    flex-basis: 45%;
  }

  &-xl {
    flex-basis: 55%;
  }

  &-xxl {
    flex-basis: 100%;
  }

  &:last-child {
    margin-right: 0;
  }
}

.edit-ad-container h2 {
  font-size: var(--font-size-medium);
  font-weight: 700;
  margin-bottom: 4rem;
}

.edit-ad-container h5 {
  font-size: var(--font-size-small);
  font-weight: 700;
  margin-top: 4rem;
}

.edit-ad-container span {
  font-size: var(--font-size-xx-small);
  font-weight: 400;
}

.edit-ad-container p {
  font-size: var(--font-size-xx-small);
  line-height: 24px;
  max-width: 100%;
  margin-bottom: 2rem;

}
.edit-ad-information-italics{
  font-style: italic;
  font-size: 0.9em !important;
}
.edit-ad-input-row label,
.edit-ad-input-price-row label {
  display: block;
  font-size: var(--font-size-xx-small);
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
  white-space: nowrap;
}

.edit-ad-container select,
.edit-ad-container input[type="text"] {
  width: 100%;
  height: 4rem;
  font-size: var(--font-size-xx-small);
  border: 1px solid #c6cbcf;
  border-radius: 100px;
  background: var(--white);
  padding: 0 2.5rem;
}

.edit-ad-vat-tax-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
}

.edit-ad-vat-tax-wrapper svg {
  height: 1.75rem;
  width: 1.75rem;
  margin-left: 0.5rem;
}

.full-vacancy-checkbox-wrapper {
  position: relative;
  display: grid;
  grid-template-columns: 3rem auto;
  align-items: center;
}

.full-vacancy-checkbox-input {
  accent-color: var(--green);
  width: 2rem;
  height: 2rem;
}

.full-vacancy-checkbox-label {
  font-size: var(--font-size-xx-small);
}

.edit-ad-select-wrapper {
  position: relative;
}

.edit-ad-select-wrapper select {
  -webkit-appearance: none;
  appearance: none;
}

.edit-ad-select-wrapper img {
  position: absolute;
  right: 1.6rem;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.edit-ad-container .link {
  color: var(--green);
}

.edit-ad-container textarea {
  width: 100%;
  min-height: 20rem;
  padding: 1rem;
  border-radius: 20px;
  overflow-y: hidden;
  font-size: var(--font-size-xx-small);
}

.edit-ad-button-row {
  display: flex;
  justify-content: flex-end;
  margin-top: 14rem;
}

.edit-ad-button-row button {
  padding: 1.15rem 4rem;
  margin-left: 2rem;
  font-size: var(--font-size-x-small);
  line-height: 25px;
  text-decoration: none;
  white-space: nowrap;
  border-radius: 100px;
  background-color: var(--white);
  border: 2px solid var(--green);
  box-shadow: var(--shadow-light);
}

.edit-ad-button-row button:last-child {
  background-color: var(--green);
  color: var(--white);
  box-shadow: none;
  border: none;
}

.edit-ad-button-row button:disabled {
  cursor: not-allowed;
}

.edit-ad-input-price-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
}

.edit-ad-hevy-font {
  font-weight: 200;
  font-size: var(--font-size-xx-small);
  font-family: var(--Lato);
}

.tooltip {
  padding: 0.8rem 1.6rem;
  font-size: 1.6rem;
  font-weight: 700;
  z-index: var(--z-index-tooltip);
  color: var(--green);
  background-color: var(--white);
  border-radius: 4px;
}

.edit-ad-inline-msg,
.edit-ad-error-msg {
  font-size: var(--font-size-xx-small);

  margin-left: 1rem;
}

.edit-ad-image-italics{
  font-style: italic;
  font-size: var(--font-size-xx-small);
}

.edit-ad-form-buttons-disabled-info {
  color: red;
}

.edit-ad-error-msg {
  color: var(--red);
}

@media only screen and (max-width: 1350px) {
  .edit-ad-container {
    width: 98%;
  }
}

@media only screen and (max-width: 1300px) {
  .edit-ad-container {
    width: 95%;
  }
}

@media only screen and (max-width: 1250px) {
  .edit-ad-container {
    width: 92%;
  }
}

@media only screen and (max-width: 1200px) {
  .edit-ad-container {
    width: 98%;
  }
}

@media only screen and (max-width: 1150px) {
  .edit-ad-input-price-row {
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr;
  }

  .edit-ad-inline-msg {
    margin: 0;
  }

  .edit-ad-container form {
    width: 100%;
  }

  .edit-ad-input-row {
    display: flex;
    flex-direction: column;

    label {
      white-space: normal;
    }
  }

  .edit-ad-input-fastighet {
    width: 100% !important;
  }

  .edit-ad-form-buttons,
  .edit-ad-form-buttons-row {
    display: inline-block;

    button {
      width: 100%;
      margin: 10px auto;
    }
  }

  .edit-ad-form-buttons-disabled-info {
    white-space: normal;
  }

  .edit-ad-input-row .edit-ad-input-col {
    margin-right: 0;
  }
}

@media only screen and (max-width: 980px) {
  .edit-ad-button-row {
    margin-top: 2rem;
    flex-direction: column;

    >* {
      margin: 10px 0;
    }
  }
}

.edit-ad-image-container {
  display: flex;
  flex-wrap: wrap;
}

#edit-ad-image-input {
  display: none;
}

.edit-ad-custom-file-input {
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: center !important;
  background-color: var(--white);
  box-shadow: var(--shadow-light);
  width: 16.5rem;
  height: 18rem;
  margin: 0;
  border-radius: 8px;
  color: var(--text-black);
  cursor: pointer;
}

.edit-ad-custom-file-input img {
  width: 12rem;
  height: auto;
}

.edit-ad-custom-file-input span {
  display: block;
  font-size: 1.3rem;
  font-weight: 700;
  transform: translateY(-15%);
}

.edit-ad-image {
  display: inline-block;
  position: relative;
  margin-right: 2rem;
  margin-bottom: 2rem;
}

.edit-ad-image img {
  height: 18rem;
  width: auto;
  border-radius: 8px;
}

.edit-ad-form-customer-info {
  padding-top: 4rem;
}

.edit-ad-form-customer-info p {
  font-size: 1.8em;
}

.edit-ad-image-delete-button {
  position: absolute;
  top: 0.6rem;
  right: 0.6rem;
  width: 3.75rem;
  height: 3.75rem;
  font-size: 1.6rem;
  line-height: 1.5;
  color: var(--text-black);
  text-decoration: none;
  background-color: var(--white);
  border: 1px solid transparent;
  border-radius: 50%;
  cursor: pointer;
}

.edit-ad-image-delete-button-new {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  color: var(--white);
  text-decoration: none;
  padding: 0.6rem 1.6rem;
  border-radius: 50%;
}

.edit-ad-image.selected {
  border: 5px solid var(--green);
  border-radius: 8px;
  padding: 5px;
}

.edit-ad-image.selected-local {
  border: 5px solid var(--red);
  border-radius: 8px;
  padding: 5px;
}

.edit-ad-image-error-msg {
  color: var(--red);
  font-size: var(--font-size-xx-small);
}

@media only screen and (max-width: 980px) {

  .edit-ad-btn-wrapper-image-change-order {
    display: flex;
    justify-content: center;
  }

  .edit-ad-image img {
    width: 100%;
  }

  .edit-ad-form-customer-info p {
    margin-left: 0.5rem;
  }

}

@media only screen and (max-width: 560px) {
  .edit-ad-custom-file-input {
    width: 100%;
    margin-bottom: 2rem;
  }
}