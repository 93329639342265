.collaborations-section {
  padding: 1%;
  padding-top: 1rem !important;
}

.collaborations-section h5 {
  font-size: var(--font-size-medium);
  font-weight: 700;
  text-align: center;
  padding-bottom: var(--spacing-lg);
}

.collaborations-section>div {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.collaborations-section div a {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 2.5%;
  width: 15%;
}

.collaborations-section a:nth-child(n + 1):nth-child(-n + 4) {
  width: 20%;
  margin-bottom: 2.5%;
}

.single-collaborator img {
  max-width: 100%;
  max-height: 10rem;
}

.single-collaborator.sm {
  transform: scale(1.3);
}

.single-collaborator.md {
  transform: scale(2);
}

.single-collaborator.lg {
  transform: scale(2.5);
  overflow: none;
}

.single-collaborator.seven {
  margin-top: 1rem;
}

@media only screen and (max-width: 1240px) {
  .collaborations-section {
    padding: var(--spacing-xl) var(--spacing-sm);
  }
}

@media only screen and (max-width: 980px) {
  .single-collaborator.sm {
    transform: scale(1);
  }

  .single-collaborator.md {
    transform: scale(1.3);
  }

  .single-collaborator.lg {
    transform: scale(1.5);
  }

  .collaborations-section h2 {
    font-size: var(--font-size-mobile-small);
  }
}

@media only screen and (max-width: 800px) {
  .collaborations-section>div>a {
    margin: 0 3.75% 2rem !important;
    width: 25% !important;
  }
}

@media only screen and (max-width: 500px) {

  .collaborations-section>div>a {
    margin: 0 5% 2.5% !important;
    width: 40% !important;
  }
}