.filter-dropdown-button {
  padding: 0.8rem 1rem 1rem;
  font-size: var(--font-size-xx-small);
  font-weight: 500;
  text-decoration: none;
  color: var(--text-black);
  background-color: var(--white);
  box-shadow: var(--shadow-light);
  border-radius: 50px;
  border: none;
  cursor: pointer;
  width: auto;
  height: 4rem;
  transition: all 0.15s ease-in-out;
}

.filter-dropdown-button.active {
  background-color: var(--orange);
  color: var(--white);
}


.filter-dropdown-button.active .arrow-icon {
  fill: var(--white);
  filter: brightness(0) invert(1);

}

.arrow-icon {
  width: 10px;
  height: 15px !important;

}


.filter-dropdown-button:hover {
  box-shadow: var(--shadow-medium);
  transform: scale(1.05);
}

.arrow-icon {
  width: 20px;
  height: 20px;
  transition: transform 0.1s ease-in-out;
}

.arrow-icon.flipped {
  transform: scaleY(-1);
}

@media only screen and (max-width: 470px) {
  .dropdown {
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}