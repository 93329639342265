.create-ad-container {
  max-width: 100rem;
}

.form-control {
  width: 95%;
}

.radio-group {
  margin-left: -12px;
}

.shit-radio-button {
  visibility: hidden;
  cursor: pointer;
}

.custom-radio-button {
  height: 17px;
  width: 17px;
  border-radius: 50%;
  cursor: pointer;
  background-color: #fff;
  display: inline-block;
  border: 2px solid var(--green);
}

.create-ad-input-fastighet {
  width: 43rem !important;
}

.shit-radio-button:checked+span {
  background-color: var(--green);
}

.create-ad-input-row {
  display: flex;
}
  .create-ad-input-col {
    margin-right: 2rem;
    margin-bottom: 2.4rem;

    &-sm {
      flex-basis: 15%;
    }

    &-md {
      flex-basis: 20%;
    }

    &-lg {
      flex-basis: 45%;
    }

    &-xl {
      flex-basis: 55%;
    }

    &-xxl {
      flex-basis: 100%;
    }

    &:last-child {
      margin-right: 0;
    }
  }

.create-ad-information-italics {
  font-style: italic;
  font-size: 0.9em;
}
.create-ad-container h2 {
  font-size: var(--font-size-medium);
  font-weight: 700;
  margin-bottom: 4rem;
}

.create-ad-container h5 {
  font-size: var(--font-size-small);
  font-weight: 700;
  margin-top: 4rem;

  span {
    font-size: var(--font-size-xx-small);
    font-weight: 400;
  }
}

.create-ad-container p {
  font-size: var(--font-size-xx-small);
  line-height: 24px;
  max-width: 100%;
  margin-bottom: 2rem;
}

.create-ad-container label {
  display: block;
  font-size: var(--font-size-xx-small);
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
  white-space: nowrap;
}

.create-ad-container select {
  width: 100%;
  height: 4rem;
  font-size: var(--font-size-xx-small);
  border: 1px solid #c6cbcf;
  border-radius: 100px;
  background: var(--white);
  padding: 0 2.5rem;
}

.create-ad-container input[type="text"] {
  width: 100%;
  height: 4rem;
  font-size: var(--font-size-xx-small);
  border: 1px solid #c6cbcf;
  border-radius: 100px;
  background: var(--white);
  padding: 0 2.5rem;
}

.create-ad-vat-tax-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
}

.create-ad-vat-tax-wrapper svg {
  height: 1.75rem;
  width: 1.75rem;
  margin-left: 0.5rem;
}

.full-vacancy-checkbox-wrapper {
  position: relative;
  display: grid;
  grid-template-columns: 3rem auto;
  align-items: center;
}

.full-vacancy-checkbox-input {
  accent-color: var(--green);
  width: 2rem;
  height: 2rem;
}

.full-vacancy-checkbox-label {
  font-size: var(--font-size-xx-small);
}

.create-ad-select-wrapper {
  position: relative;


}

.create-ad-select-wrapper select {
  -webkit-appearance: none;
  appearance: none;
}

.create-ad-select-wrapper img {
  position: absolute;
  right: 1.6rem;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.link {
  color: var(--green);
}

.create-ad-container textarea {
  width: 100%;
  min-height: 20rem;
  padding: 1rem;
  border-radius: 20px;
  overflow-y: hidden;
  font-size: var(--font-size-xx-small);
}

.create-ad-form-customer-info {
  padding-top: 4rem;
}

.create-ad-form-customer-info p {
  font-size: 1.8em;
}

.create-ad-form-buttons {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 3.7rem auto;

}

.create-ad-form-buttons-disabled-info {
  grid-column: 2 / 3;
  font-size: var(--font-size-xx-small);
  grid-row: 1;
  color: var(--text-black);
  white-space: nowrap;
  margin-left: 3rem;
  margin-bottom: 1.5rem;
}

.create-ad-form-buttons-row {
  grid-column: 2 / 3;
  grid-row: 2;
}

.create-ad-form-buttons button {
  padding: 1.15rem 4rem;
  margin-left: 2rem;
  font-size: var(--font-size-x-small);
  line-height: 25px;
  text-decoration: none;
  white-space: nowrap;
  border-radius: 100px;
  background-color: var(--white);
  border: 2px solid var(--green);
  box-shadow: var(--shadow-light);
  cursor: pointer;
}

.create-ad-form-buttons button:last-child {
  background-color: var(--green);
  color: var(--white);
  box-shadow: none;
  border: none;
}

.create-ad-form-buttons button:disabled {
  cursor: not-allowed;
}

.create-ad-input-price-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
}

.create-ad-hevy-font {
  font-weight: 200;
  font-size: var(--font-size-xx-small);
  font-family: var(--Lato);
}

.tooltip {
  padding: 0.8rem 1.6rem;
  font-size: 1.6rem;
  font-weight: 700;
  z-index: var(--z-index-tooltip);
  color: var(--green);
  background-color: var(--white);
  border-radius: 4px;
}

.create-ad-inline-msg,
.create-ad-error-msg {
  font-size: var(--font-size-xx-small);
  margin-left: 1rem;
}

.create-ad-image-italics {
  font-style: italic;
  font-size: var(--font-size-xx-small);
}

.create-ad-image-container {
  display: flex;
  flex-wrap: wrap;

}


.create-ad-form-buttons-disabled-info {
  color: red;
}

.create-ad-error-msg {
  color: var(--red);
}

@media only screen and (max-width: 1350px) {
  .create-ad-container {
    width: 98%;
  }
}

@media only screen and (max-width: 1300px) {
  .create-ad-container {
    width: 95%;
  }
}

@media only screen and (max-width: 1250px) {
  .create-ad-container {
    width: 92%;
  }
}

@media only screen and (max-width: 1200px) {
  .create-ad-container {
    width: 98%;
  }
}

@media only screen and (max-width: 1150px) {
  .create-ad-input-price-row {
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr;
  }

  .css-heg063-MuiTabs-flexContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
  }

  .css-k008qs {
    display: block;
  }

  .css-1anid1y {
    white-space: normal;
  }

  .css-5xfbct-MuiTabs-root {
    border-bottom: none;
  }

  .css-ayo58a-MuiButtonBase-root-MuiTab-root.Mui-selected {
    font-weight: bolder;
  }

  .css-5xfbct-MuiTabs-root .MuiTabs-indicator {
    display: none;
  }

  .create-ad-inline-msg {
    margin: 0;
  }

  .create-ad-container form {
    width: 100%;
  }

  .create-ad-input-row {
    display: flex;
    flex-direction: column;

    label {
      white-space: normal;
    }
  }

  .create-ad-input-fastighet {
    width: 100% !important;
  }

  .create-ad-form-buttons,
  .create-ad-form-buttons-row {
    display: inline-block;
  }

  .create-ad-form-buttons button,
  .create-ad-form-buttons-row button {
    width: 100%;
    margin: 10px auto;
    cursor: pointer;
  }

  .create-ad-form-buttons-disabled-info {
    white-space: normal;
  }

  .create-ad-input-row .create-ad-input-col {
    margin-right: 0;
  }
}

.create-ad-custom-file-input {
  display: flex !important;
  flex-wrap: wrap;
  justify-content: center;

}

#create-ad-image-input {
  display: none;
}

.create-ad-custom-file-input {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  box-shadow: var(--shadow-light);
  width: 16.5rem;
  height: 18rem;
  margin: 0;
  border-radius: 8px;
  color: var(--text-black);
  cursor: pointer;
}

.create-ad-custom-file-input img {
  width: 12rem;
  height: auto;
}

.create-ad-custom-file-input span {
  display: block;
  font-size: 1.3rem;
  font-weight: 700;
  transform: translateY(-15%);
}

.create-ad-image {
  display: inline-block;
  position: relative;
  margin-right: 2rem;
  margin-bottom: 2rem;
}

.create-ad-image img {
  width: auto;
  height: 18rem;
}

.create-ad-custom-file-input &>img {
  height: 18rem;
  width: auto;
  border-radius: 8px;
}

.create-ad-image-delete-button {
  position: absolute;
  top: 0.6rem;
  right: 0.6rem;
  width: 3.75rem;
  height: 3.75rem;
  font-size: 1.6rem;
  line-height: 1.5;
  color: var(--text-black);
  text-decoration: none;
  background-color: var(--white);
  border: 1px solid transparent;
  border-radius: 50%;
  cursor: pointer;
}

@media only screen and (max-width: 980px) {
  .create-ad-custom-file-input {
    width: 100%;
  }

  .create-ad-form-customer-info p {
    margin-left: 0.5rem;
  }


  .create-ad-image img {
    width: 100%;
    height: 18rem;
  }
}