.active-link-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: 600;
  border: none;
  border-radius: 50px;
  box-shadow: var(--shadow-light);
  transition: all 0.15s ease-in-out;
  cursor: pointer;
  gap: 1.25rem;
}

.active-link-button:hover {
  box-shadow: var(--shadow-medium);
  transform: scale(1.05);
}

.active-link-button.active {
  color: var(--white);
  background-color: var(--orange);
}

.active-link-button:not(.active) {
  color: var(--text-black);
  background-color: var(--white);
}

.active-link-button.sm {
  height: 4rem;
  width: 13rem;
}

.active-link-button.md {
  height: 5.8rem;
  width: 35rem;
  padding: 1rem 1rem 1rem !important;
}

.active-link-button.lg {
  height: 7rem;
  width: 18rem;
}