.sustainability-outer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: center;
  max-width: 1600px;
  margin: 0 auto;
}

.sustainability-inner {
  padding: var(--spacing-lg);
  max-width: 1600px;
  margin: 2rem;
}

.sustainability-container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1600px;
  margin: 0 auto;

}

.sustainability-intro-text h1 {
  color: var(--green);
  font-size: var(--font-size-large);
  word-break: break-all;
}

.sustainability-intro-text h3 {
  font-size: var(--font-size-x-small);
}

.sustainability-intro-text h2 {
  font-size: var(--font-size-medium);
  text-align: left;
  margin-bottom: 0rem;
}

.sustainability-intro-text p,
.sustainability-intro-text b,
.sustainability-intro-text li {
  margin: 0;
  font-size: var(--font-size-xx-small);
}

.sustainability-intro-text a {
  color: var(--green);
}

.sustainability-vision-text h2 {
  text-align: left;
  font-size: var(--font-size-medium);
  margin-bottom: 0rem;
}

.sustainability-vision-text p {
  font-size: var(--font-size-xx-small);
}

.sustainability-container img {
  max-width: 800px;
  width: 100%;
  height: auto;
  padding: 0;
}

.vision-section .sustainability-vision-text h2 {
  text-align: left;
  font-size: var(--font-size-medium);
  margin-bottom: 0rem;
}

.sustainability-intro-text ol {
  margin-left: 4rem;
}

.sustainability-img {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 1040px) {
  .sustainability-inner {
    padding: 0;
  }
}

@media only screen and (max-width: 980px) {

  .sustainability-intro-text h1 {
    font-size: var(--font-size-large);
  }

  .sustainability-intro-text h2 {
    font-size: var(--font-size-mobile-small);
  }
}