.ui.styled.accordion {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.ui.styled.accordion .title {
  cursor: pointer;
  padding: 15px;
  border-bottom: 1px solid #ccc;
  font-size: medium;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ui.styled.accordion .content {
  display: none;
  font-size: 1.5rem;
  color: black;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  word-wrap: break-word;
  overflow-wrap: break-word;
  overflow: hidden;
  line-height: 2.5;
}

.ui.styled.accordion .active.content {
  display: block;
}

.ui.styled.accordion .active.title {
  background-color: #fffbfb;
}
